import { FC } from "react";
import clsx from "clsx";
import { ThemeModeSwitcher } from "../../../partials";
import { useAuth } from "../../../../app/modules/auth/core/Auth";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  toolbarButtonHeightClass =
    "btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p",
  toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px";

const Topbar: FC = () => {
  const { currentUser, logout } = useAuth();
  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      <div className="topbar d-flex align-items-stretch flex-shrink-0">
        {/* begin::Theme mode */}
        <div
          className={clsx(
            "d-flex align-items-center",
            toolbarButtonMarginClass
          )}
        >
          <ThemeModeSwitcher toggleBtnClass={toolbarButtonHeightClass} />
        </div>
        {/* end::Theme mode */}

        {/* begin::User */}
        <div
          className={clsx(
            "d-flex align-items-center",
            toolbarButtonMarginClass
          )}
          id="kt_header_user_menu_toggle"
        >
          {/* begin::Toggle */}
          <div
            className={clsx(
              "cursor-pointer symbol",
              toolbarUserAvatarHeightClass
            )}
            data-kt-menu-trigger="click"
            data-kt-menu-attach="parent"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="bottom"
          >
            <div className="symbol-label fs-3 bg-light-primary rounded-1 text-primary">
              {currentUser?.fullname.charAt(0).toUpperCase()}
            </div>
          </div>
          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <div className="menu-content d-flex align-items-center px-3">
                <div className="symbol symbol-50px me-5">
                  <div className="symbol-label fs-3 bg-light-primary rounded-1 text-primary">
                    {currentUser?.fullname.charAt(0).toUpperCase()}
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <div className="fw-bold d-flex align-items-center fs-5">
                    {currentUser?.fullname}
                  </div>
                  <span className="fw-semibold text-muted text-hover-primary fs-7">
                    {currentUser?.email}
                  </span>
                </div>
              </div>
            </div>
            <div className="separator my-2"></div>
            <div className="menu-item px-5">
              <span onClick={logout} className="menu-link px-5">
                Sign Out
              </span>
            </div>
          </div>
          {/* end::Toggle */}
        </div>
        {/* end::User */}
      </div>
    </div>
  );
};

export { Topbar };
