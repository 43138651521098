import ReactDOM from "react-dom/client";
import axios from "axios";
import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n";
import "./_metronic/assets/sass/style.react.scss";
import "./_metronic/assets/fonticon/fonticon.css";
import "./_metronic/assets/keenicons/duotone/style.css";
import "./_metronic/assets/keenicons/outline/style.css";
import "./_metronic/assets/keenicons/solid/style.css";
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/style.react.scss";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";
import "./_metronic/assets/sass/custom.scss";

import { AppRoutes } from "./app/routing/AppRoutes";
import { AuthProvider } from "./app/modules/auth/core/Auth";
import { setupAxios } from "./app/modules/auth/core/AuthHelpers";

setupAxios(axios);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <MetronicI18nProvider>
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  </MetronicI18nProvider>
);
