import axios from "axios";
import { AuthModel, UserModel } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;

const LOGIN_URL = `${API_URL}/auth/login`;
const VERIFY_URL = `${API_URL}/auth/verify`;

export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  });
}

export function verify() {
  return axios.get<UserModel>(VERIFY_URL);
}
